
// Override default variables before the import
// $body-bg: #000;
$primary:   #D61F84; /* #FF5EB5; */
$highlight: #e1aeff;
$secondary: #6c757d;
$success:   #28a745;
$info:      #17a2b8;
$warning:   #ffc107;
$danger:    #dc3545;
$light:     #f8f9fa;
$dark:      #343a40;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$theme-colors: map-merge( $theme-colors, ("highlight": $highlight ));

$nav-link-color:                  $secondary;
$nav-underline-gap:               1rem;
$nav-underline-border-width:      2px;
$nav-underline-link-active-color: $primary;

// $accordion-button-bg:             var(--bs-info-bg-subtle);
// $accordion-button-active-bg:      var(--bs-info-bg-emphasis);

$custom-shadows: (
  null:   $box-shadow,       // shadow
  "1":    $box-shadow-sm,    // shadow-1
  "2":    $box-shadow,       // shadow-2
  "3":    $box-shadow-lg,    // shadow-3
  "none": none               // shadow-none
);

// 4. Add to utilities API
$utilities: map-merge(
  $utilities,
  (
    "shadow": (
      property:   box-shadow,
      class:      shadow,
      state:      hover focus,     // Enables hover and focus variants
      values:     $custom-shadows,
      responsive: true             // This enables all breakpoint variations
    )
  )
);

// Import Bootstrap and its default variables
@import 'bootstrap/scss/bootstrap.scss';

//this is some experimentation with responsive borders, I couldn't get it to have backwards compat
// $utilities: map-merge(
//   $utilities,
//   (
//     "border": map-merge(
//       map-get($utilities, "border"),
//       ( responsive: true ),
//     ),
//   )
// );

// $utilities: map-merge(
//   $utilities,
//   (
//     "border-width": map-merge(
//       map-get($utilities, "border-width"),
//       ( responsive: true ),
//     ),
//   )
// );

// $utilities: map-merge(
//   $utilities,
//   (
//     "border-primary": (
//       responsive: true,
//       property: border,
//       class: border-primary,
//       values: (
//         null: 1px solid var(--bs-primary),  // default is 1px
//         0: 0,                               // border-primary-0
//         1: 1px solid var(--bs-primary),     // border-primary-1 (same as default)
//         2: 2px solid var(--bs-primary),     // border-primary-2
//         3: 3px solid var(--bs-primary),     // border-primary-3
//       )
//     )
//   )
// );