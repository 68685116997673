@property --color-bg {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #2e2f42;
}

@property --color-inactive {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #d6e2fb;
}

@property --color-active {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #f7f7f7;
}

@property --color-shadow {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #aec6f6;
}

@property --color-off-white {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #cbd4de;
}

@property --color-light {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #FFC1E3;
}

@property --color-mid {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #FF5EB5;
}

@property --color-dark {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #D61F84;
}
@property --color-very-dark {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #6B1042;
}

@font-face {
    font-family: 'silka';
    src: local('silka'), url(../fonts/silka-regular-webfont.woff2) format('woff2');
    font-weight: normal;

    /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'silka';
    src: local('silka'), url(../fonts/silka-semibold-webfont.woff2) format('woff2');
    font-weight: 600;
}

@font-face {
    font-family: 'silka';
    src: local('silka'), url(../fonts/silka-bold-webfont.woff2) format('woff2');
    font-weight: bold;
}

body {
    font-family: silka, Roboto, Helvetica, Arial, sans-serif !important;
    /* font-feature-settings: ; */
    /* adding important fixed a bug with google maps modifying my fonts */
}

/* some simple utilities */
.fullwidth       { width:          100%;  }
.fullheight      { height:         100%;  }
.scroll-x        { overflow-x:   scroll;  }
.scroll-x-auto   { overflow-x:     auto;  }
.scroll-y        { overflow-y:   scroll;  }
.scroll-y-auto   { overflow-y:     auto;  }
.w-10            { width:      10.0000%;  }
.w-20            { width:      20.0000%;  }
.w-30            { width:      30.0000%;  }
.w-40            { width:      40.0000%;  }
.w-60            { width:      60.0000%;  }
.w-70            { width:      70.0000%;  }
.w-80            { width:      80.0000%;  }
.w-33            { width:      33.3333%;  }
.w-66            { width:      66.6666%;  }
.min-w-33        { min-width:  33.3333%;  }
.min-w-66        { min-width:  66.6666%;  }

/** grid utilities **/
/* cols */
.grid-col-1      { grid-column-start: 1; }
.grid-col-2      { grid-column-start: 2; }
.grid-col-3      { grid-column-start: 3; }
.grid-col-4      { grid-column-start: 4; }
.grid-col-5      { grid-column-start: 5; }

/* col span */
.grid-col-span-1 { grid-column: span 1 }
.grid-col-span-2 { grid-column: span 2 }
.grid-col-span-3 { grid-column: span 3 }
.grid-col-span-4 { grid-column: span 4 }
.grid-col-span-5 { grid-column: span 5 }

/* rows */
.grid-row-1      { grid-row-start: 1; }
.grid-row-2      { grid-row-start: 2; }
.grid-row-3      { grid-row-start: 3; }
.grid-row-4      { grid-row-start: 4; }
.grid-row-5      { grid-row-start: 5; }

/* row span */
.grid-row-span-1 { grid-row: span 1; }
.grid-row-span-2 { grid-row: span 2; }
.grid-row-span-3 { grid-row: span 3; }
.grid-row-span-4 { grid-row: span 4; }
.grid-row-span-5 { grid-row: span 5; }

.grid-area-a { grid-area: a; }
.grid-area-b { grid-area: b; }
.grid-area-c { grid-area: c; }
.grid-area-d { grid-area: d; }
.grid-area-e { grid-area: e; }
.grid-area-f { grid-area: f; }

.d-flex-align-center {
    display: flex;
    align-items: center;
    gap: 1em;
}

.cursor-pointer {
    cursor: pointer;
}

#main {
    height:             100vh;
    display:            grid;
    grid-template-rows: auto 1fr;
    /* top bar should be whatever size it desires          */
    /* content should scroll but leave the top bar present */
}

/*  Each page is given an #id such as
        #landing-page
        #results-page
    Unique sections in a page are assigned an id such as
        #landing-page-search
*/

#content {
    overflow: auto;
    height:   100%;
}

#landing-page {
    width:  100%;
    height: 100%;
}

#landing-page-window {
    display:               grid;
    grid-template-columns: 1fr;
    grid-template-rows:    max-content max-content max-content;
    /* border:             1px  var(--color-mid) solid; */
    background-color:      white;

    /* to get them centered on the screen */
    position:              relative;
    top:                   -2%;
}

@media (min-width: 768px) {
    #landing-page-window {
        box-shadow: var(--bs-box-shadow-lg) !important;
        border:     2px solid var(--bs-primary);
    }
}

@media (max-width: 768px) {
    .collapse-window {
        border:           0px solid white;
        background-color: white;
        padding:          0.25em;
        margin:           0.25em;
        /* box-shadow: var(--bs-box-shadow-lg) !important; */
        /* border:     2px solid var(--bs-primary); */
    }
}

#landing-hourly-dates {
    display              : grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows   : 1fr 1fr;
    gap                  : 0.5em;
}

#monthly-search-dates {
    display              : grid;
    grid-template-columns: 1fr;
    grid-template-rows   : 1fr;
    gap                  : 0.5em;
    margin-bottom        : 0em;
}

.facility-result {
    display:               grid;
    grid-template-columns: max-content 1fr;
    cursor:                pointer;
}

.facility-result:hover {
    border: 2px solid red;
}

.facility-result-pic {
    max-width:     100px;
    border-radius: 1em;
    margin-right:  1em;
}

.facility-result-pic-slim {
    max-width:     100px;
    border-radius: 1em;
}

.facility-result-info {
    display:               grid;
    grid-template-columns: 1fr auto;
    grid-template-rows:    1fr auto;
}

.facility-result-bottom {
    grid-row:              2;
    grid-column:           span 2;
    display:               grid;
    grid-template-columns: minmax( max-content, 1fr ) minmax( max-content, 1fr );
}

.status-btn:hover, .status-btn:focus, .status-btn:not(:disabled):not(.disabled):active, .status-btn:not(:disabled):not(.disabled).active, .show > .status-btn.dropdown-toggle {
    color: var(--bs-body-color);
    background-color: var(--bs-body);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(255,94,181,.25);
    overflow: visible;
}

.monthly-sub-info-text {
    font-size:   0.75em;
    gap:         0;
    margin:      0;
    padding:     0;
    grid-column: span 1;
    position:    relative;
    top:         -1.5em;
}

.purchase-layout {
    display: grid;
    grid-template-areas: "a b c"
                         "a d d";
    grid-template-columns: auto 1fr auto;
    grid-template-rows:    auto auto;
}

.credit-card {
    width:               15em;
    display:             inline-grid;
    aspect-ratio:        85 / 53;
    grid-template-areas: "a a a"
                         ". . ."
                         "b b b"
                         "c c c";
    gap:               0.25em;
    grid-auto-columns: 1fr;
    grid-auto-rows:    1fr;
    /* background: rgb(203,203,203); */
    /* background: linear-gradient(325deg, rgba(203,203,203,1) 0%, rgba(255,255,255,1) 100%); */
}

@media (min-width: 768px) {
    .my-active {
        background-color: var(--bs-primary);
        color:            white;
    }
}

/* google maps stuff */
.gm-style iframe + div { border: none !important; }

/* .pac-container {} */
/* .pac-icon {}      */

.pac-item {
    padding:    0.25em;
    border-top: 0px solid #e6e6e6;
    font-size:  1em;
}

/* .pac-item:hover    {} */
/* .pac-item-selected {} */
.pac-item-query {
    font-size: 1em;
}
/* .pac-matched       {} */

.pac-container:after{
    /* content:none !important; */
    /* height: 0px; */
}

.zoom-small:hover {
    transition: transform .25s;
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.zoom-small:not(:hover) {
    transition: transform .25s;
    transform: scale(1.00); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/* Full-page overlay */
.page-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dialog styling */
.mask-dialog {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
}

.mask-dialog h2 {
    margin-bottom: 1rem;
}

.mask-dialog p {
    margin: 0;
    font-size: 1rem;
}

/* Horizontal Loader */
.mask-loader {
    width: 80%;
    height: 4px;
    background: linear-gradient(
        90deg,
        rgba(214, 31, 132, 0.2), /* Light transparent pink */
        #d61f84, /* Solid pink */
        rgba(214, 31, 132, 0.2) /* Light transparent pink */
    );
    background-size: 200%;
    animation: loading 1.5s infinite;
    border-radius: 2px;
}

/* Animation for loader */
@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

/* End of Full-page overlay */

/* hide arrows  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}